// src/components/ContactFormPopup.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Modal, Fade, Backdrop, IconButton, CircularProgress, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const ContactFormPopup = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    dateTime: '',
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });

  useEffect(() => {
    if (open) {
      setFormData((prevData) => ({
        ...prevData,
        dateTime: new Date().toLocaleString(),
      }));
    }
  }, [open]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const scriptURL = "https://script.google.com/macros/s/AKfycbyzTI3c8OSU3kIqnH7dGfAAtarsm0f_BA-86Cf778-nIqL1g0Ju6-ZENrK900JbIttAFw/exec";
      
      await axios.post(scriptURL, new URLSearchParams(formData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      setAlert({ open: true, severity: 'success', message: 'Form submitted successfully!' });
      setFormData({ name: '', email: '', phone: '', subject: '', message: '', dateTime: '' });
    } catch (error) {
      console.error("Error submitting form:", error);
      setAlert({ open: true, severity: 'error', message: 'Submission error, please try again' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: 400 }, // Adjust width for mobile
              maxWidth: '100%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: { xs: 2, sm: 4 }, // Padding for mobile and tablet
              borderRadius: '8px',
              position: 'relative',
            }}
          >
            <IconButton
              onClick={onClose}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" mb={2}>
              Contact Us
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Name"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={formData.name}
                required
              />
              <TextField
                name="email"
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={formData.email}
                required
              />
              <TextField
                name="phone"
                label="Phone"
                type="tel"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={formData.phone}
              />
              <TextField
                name="subject"
                label="Subject"
                fullWidth
                margin="normal"
                onChange={handleChange}
                value={formData.subject}
              />
              <TextField
                name="message"
                label="Message"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                onChange={handleChange}
                value={formData.message}
              />
              <Box sx={{ position: 'relative', mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>

      {/* Success/Error Alert */}
      <Snackbar open={alert.open} autoHideDuration={4000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactFormPopup;

import React from 'react';
import { Box, Typography, Grid, Button, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import Header from '../components/Header';
import Footer from '../components/Footer';

// Importing assets for each API service
import aepsImg from '../assest/aeps-icon.png';
import bbpsImg from '../assest/bbps-icon.png';
import dmtImg from '../assest/dmt-api.png';
import upiImg from '../assest/upi-api.png';
import payoutImg from '../assest/payout-api.png';
import pancardImg from '../assest/pancard-icon.png';
import whatsappImg from '../assest/whatsapp-api.png';
import aadhaarImg from '../assest/aadhar-verification-api.png';
import rechargeImg from '../assest/rechagreapi.webp';
import APIIMG1 from '../assest/apiimg1.png';

const services = [
  { id: 'aeps', title: 'AEPS API', image: aepsImg },
  { id: 'bbps', title: 'BBPS API', image: bbpsImg },
  { id: 'dmt', title: 'DMT API', image: dmtImg },
  { id: 'upi', title: 'UPI Payment API', image: upiImg },
  { id: 'payout', title: 'Payout API', image: payoutImg },
  { id: 'pancard', title: 'Pancard API', image: pancardImg },
  { id: 'whatsapp', title: 'Whatsapp API', image: whatsappImg },
  { id: 'aadhaar', title: 'Aadhaar Verification API', image: aadhaarImg },
  { id: 'recharge', title: 'Recharge API', image: rechargeImg },
];

const ApiServices = () => {
  return (
    <>
      <Header />

      {/* Hero Section */}
      <Box 
        sx={{
          width: '100%',
          backgroundImage: `url(${APIIMG1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          py: 8,
          color: '#fff',
          textAlign: 'center',
          pt: '8rem', // Add padding-top
          pb: 8,  // Optional: Keep bottom padding for spacing consistency
        }}
      >
        <Typography variant="h2" gutterBottom>
          API Integration Made Easy
        </Typography>
        <Typography variant="h6" paragraph>
          Connect your business with the latest technologies using our wide range of APIs.
        </Typography>
        <Button variant="contained" color="secondary" size="large">
          Get Started
        </Button>
      </Box>

      {/* Main Content */}
      <Box id="services-section" sx={{ paddingTop: '4rem', paddingBottom: '4rem', backgroundColor: '#f9f9f9' }}>
        
        {/* Section Heading */}
        <Box textAlign="center" mb={4}>
          <Typography variant="h4" gutterBottom>
            Boost Your Financial Operations with Deeva Payon API Services
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            At Deeva Payon, we deliver best-in-class APIs that meet your unique business needs.
          </Typography>
        </Box>

        {/* Service Icons Section */}
        <Box sx={{ px: 5 }}> {/* Add horizontal padding to the whole section */}
          <Grid container spacing={3} justifyContent="center">
            {services.map((service) => (
              <Grid item xs={6} sm={4} md={3} key={service.id}>
                <Paper
                  sx={{
                    p: 2,
                    textAlign: 'center',
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 6 },
                    borderRadius: 3,
                    transition: 'box-shadow 0.3s ease',
                  }}
                >
                  <img src={service.image} alt={`${service.title}`} width="50%" />
                  <Typography variant="subtitle1" mt={1}>
                    {service.title}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.href = `/api-service/${service.id}`}
                    component={motion.div}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    sx={{
                      mt: 2,
                      backgroundColor: '#f05a22', // Your accent color
                      '&:hover': {
                        backgroundColor: '#e14d1f', // Darker shade on hover
                      },
                    }}
                  >
                    Read More
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Call to Action */}
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Button variant="contained" color="primary" size="large">
            Contact Us for API Integration
          </Button>
        </Box>
      </Box>

      <Footer />
    </>
  );
};

export default ApiServices;
